<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <div class="input-container">
        <input
          v-model="amount"
          type="number"
          value="0"
          min="0"
          class="input-standard-white width600 fadeIn-2"
          id="amount"
        /><span class="input-icon fadeIn-3">£</span>
        <label for="amount">Amount</label>
      </div>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/pets/primary'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'

export default {
  name: 'PetsCash',
  components: {
    NavQuestion,
    NavQuestionButtons
  },
  computed: {
    partner() {
      return this.$store.getters.partner
    },
    people() {
      return this.$store.getters.people
    },
    isValidated() {
      return true
    },
    number() {
      if (this.partner) return '4'
      return '3'
    },
    details() {
      return this.$store.getters.details
    },
    heading() {
      return 'Would you like to leave money for the care of your pets?'
    },
    subHeading() {
      return (
        '  Over their lifetime, the average cost of owning a pet is around £16,000 for a dog or cat. Depending on the food ' +
        'you buy and sudden medical expenses, the costs could be much higher. You may choose to leave a sum of money together with ' +
        'any pets that can be used to help towards these expenses.'
      )
    },
    forwardTo() {
      return '/pets/check_and_confirm'
    }
  },
  watch: {
    details: {
      immediate: true,
      deep: true,
      handler(value) {
        this.amount = value.amount
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.save()
    next()
  },
  data() {
    return {
      loading: false,
      amount: null
    }
  },
  methods: {
    save() {
      if (this.details) {
        this.$store.commit('details', {
          amount: this.amount
        })
      }
    }
  }
}
</script>

<style></style>
